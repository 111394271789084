
  import { Component, Watch } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import { AppraisalDetail } from '@/entities/purchase'
  import { plainToInstance } from 'class-transformer'
  import { RotationIndex } from '@/entities/vehicle'
  import dayjs from 'dayjs'
  import { fixPrice } from '@/utils/general'

@Component
  export default class ExpandableAppraisalAnalysis extends Expandable {
  details: AppraisalDetail = plainToInstance(AppraisalDetail, {})
  rotation: RotationIndex = plainToInstance(RotationIndex, {})
  kms: string = '-'
  display = true

  @Watch('value', { immediate: true })
  async onValueChange (val) {
    if (!val?.id) {
      this.display = false
      return
    }
    const detail = await this.fetchData({
      query: { name: 'find', model: 'AppraisalDetail' },
      filter: { id_appraisal: { _eq: val.id } },
      force: true,
    })

    this.details = detail?.[0]

    if (this.details?.rotationIndex) {
      const rotation = await this.fetchData({
        query: { name: 'find', model: 'RotationIndex' },
        filter: { id: { _eq: this.details.rotationIndex } },
      })
      this.rotation = rotation[0]
    }
    this.kms = val.kms ? val.kms : '-'
  }

  getResidualValue (value, percentage) {
    return `${fixPrice(value)} (${percentage * 100}%)`
  }

  get kmsExpected () {
    const { kms, kmsYears } = this

    return Math.round((Number(kms?.replace('.', '') || 0) / kmsYears) * 100)
  }

  get kmsYears () {
    const { details, differentYear } = this

    return details.averageMileageConfig * differentYear
  }

  get residualValue () {
    const { details } = this

    return ((details.zeroMileagePrice * details.residualPercentage))
  }

  get year () {
    const { value } = this

    return Number(value.deal.auto.version.year)
  }

  get differentYear () {
    const { year } = this

    const currentYear = dayjs().year()
    const diff = currentYear - year
    return !diff ? 1 : diff
  }

  get semiNew () {
    const { year } = this
    const currentYear = dayjs().year()

    return Number(year) - Number(currentYear) >= -3
  }
  }
